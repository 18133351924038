

















































































































































































































import {Component, Vue} from 'vue-property-decorator';
import CGkkpSetting from '@/modules/budget-request/budget-gkkp/gkkp-settings.vue';
import CGuLimit from '@/modules/budget-request/budget-gkkp/limit-gu.vue';
import CLimitGkkp from '@/modules/budget-request/budget-gkkp/limit-gkkp.vue';
import { setNameLang, makeToast } from '@/modules/budget-request/components/js/some-functions';
import CBudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import store from '@/services/store';
import { variantNameLangSupport } from '@/modules/budget/budgetCorrectHelper';
import { Ax } from '@/utils';

@Component({
    components: {
        'gkkp-setting': CGkkpSetting,
        'c-budg-form-lst': CBudgetFormsList,
        'c-limit-gu': CGuLimit,
        'c-limit-gkkp': CLimitGkkp
    }
})
export default class CBudgetGkkp extends Vue {

    private budgetLevel: number[] = [];

    private curFormSelect: any = '/budget-gkkp';

    private activeVariant = false;

    private variantEndDate: any = null; // дата окончания вариантов, для ограничения справочников фильтров по датам

    private tabIndx = 0;
    private tabIndxSelected = 0;

    private limitGuEnable = true;
    private limitGuEditable = false;

    private gkkpEnable = true;
    private gkkpEditable = false;

    private settingEnable = false;
    private settingEditable = false;
    
    // ---------- период -------------------
    private periodLst: any[] = [];
    private curPeriod: any | null = null;

    private getPeriodLst() {
        const year = (new Date()).getFullYear();
        this.periodLst = [];
        for (let i = year - 2020 + 1; i > 0; i--) {
            this.periodLst.push({ name: `${2020 + i} - ${2020 + i + 2}`, year: 2020 + i });
        }
        this.curPeriod = { name: `${year + 1} - ${year + 3}`, year: year + 1 };
    }

    // ------------------ регион ------------------
    private regionBase: any[] = [];
    private curRegion: any | null = null;

    private get getRegion() {
        const result: any[] = [];
        for (const el of this.regionBase) {
            result.push(setNameLang(this.$i18n.locale, el, 'code'));
        }
        if (this.curRegion !== null && !this.curRegion.name) { this.curRegion = setNameLang(this.$i18n.locale, this.curRegion, 'code'); }
        return result;
    }

    private obl: string | null = null;

    // ------------вид данных-----------
    private dataTypeDictBase: any[] = [];
    private curDataType: any | null = null;

    private get dataTypeDict(): any[] {
        const result: any[] = [];
        for (const el of this.dataTypeDictBase) {
            result.push(setNameLang(this.$i18n.locale, el, 'code'));
        }
        if (this.curDataType !== null) { this.curDataType = setNameLang(this.$i18n.locale, this.curDataType, 'code'); }
        return result;
    }

    private chgDataType() {
        this.loadBudgetVersionDict();
    }

    // ------------------ администратор программ ------------------
    private abpBase: any[] = [];
    private curAbp: any | null = null;

    private get abp(): any[] {
        const res: any[] = [];
        for (const el of this.abpBase) {
            res.push(setNameLang(this.$i18n.locale, el, 'abp'));
        }

        if (this.curAbp !== null && !this.curAbp.name) { this.curAbp = setNameLang(this.$i18n.locale, this.curAbp, 'abp'); }
        return res;
    }

    // --------- гос учреждение ------------
    private guBase: any[] = [];
    private curGu: any | null = null;

    private get gu(): any[] {
        const result: any[] = [];
        for (const el of this.guBase) {
            result.push(setNameLang(this.$i18n.locale, el, 'code'));
        }
        result.sort((a, b) => (a.code > b.code) ? 1 : -1);
        return result;
    }

    // --------- гос учреждение ограниченное датой------------
    private guLimitBase: any[] = [];
    private curGuLimit: any | null = null;

    private get guLimit(): any[] {
        const result: any[] = [];
        for (const el of this.guLimitBase) {
            result.push(setNameLang(this.$i18n.locale, el, 'code'));
        }
        return result;
    }

    // ------------версия бюджета-----------
    private variantBase: any[] = [];
    private curVariant: any | null = null;

    private get variantLst() {
        const res: any[] = [];
        for (const el of this.variantBase) {
            res.push(setNameLang(this.$i18n.locale, el));
        }
        if (this.curVariant !== null) { this.curVariant = setNameLang(this.$i18n.locale, this.curVariant); }
        return res;
    }

    // ----------------------------------- loadDicts --------------------------------------


    private async getObl() {
        try {
            const result = await fetch('/api-py/get-budget-obl/' + store.state._instanceCode);
            if (result.status === 200) {
                const json = await result.json();
                this.obl = json.obl;
            } else {
                makeToast(this, 'danger', 'get-budget-obl', `${result.status} - ${result.statusText}`);
            }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка запроса get-budget-obl', (error as Error).toString());
        }

        if (!this.usrId) { return; }

        try {
            await fetch('/api-py/get-user-regions-by-obl/' + this.obl + '/' + this.usrId)
                .then(response => response.json())
                .then(json => {
                    if (json.length && json[0].code) {
                        json.sort((a: any, b: any) => (a.code - b.code > 0) ? 1 : -1);
                        this.regionBase = json;
                    }

                });
        } catch (error) {
            this.regionBase = [];
            makeToast(this, 'danger', 'Ошибка запроса get-user-budget-region', (error as Error).toString());
        }
        if (this.regionBase.length) { 
            this.curRegion = setNameLang(this.$i18n.locale, this.regionBase[0], 'code');
        }
    }


    private getBudgetLevel() {
        this.curAbp = null;
        this.budgetLevel = [];
        if (this.curRegion) {
            if (this.curRegion.code.slice(this.curRegion.code.length - 4) === '0101') {
                this.budgetLevel.push(2);
            } else if (this.curRegion.code.slice(this.curRegion.code.length - 2) === '01')  {
                this.budgetLevel.push(3);
            } else if (this.curRegion.code.slice(this.curRegion.code.length - 2) !== '00') {
                this.budgetLevel.push(4);
            }
        }
        this.abpBase = [];
        this.loadAbp();
    }

    private async loadAbp() {
        if (this.abpBase.length > 0) { return; }
        if (!this.budgetLevel || this.budgetLevel.length < 1) {
            this.curAbp = null;
            return;
        }
        const url = `/api-py/get-dict-ved-abp-by-budgetlevel-user/${encodeURI(JSON.stringify(this.budgetLevel))}/${this.usrId}`;
        let response: any = [];
        try {
            response = await fetch(url);
            if (response.status === 200) {
                response = await response.json();
            } else {
                makeToast(this, 'danger', 'Ошибка загрузки адм. программ', `${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            response = [];
            makeToast(this, 'danger', 'Ошибка загрузки адм. программ', (error as Error).toString());
        }

        response.sort((a: any, b: any) => (a.abp - b.abp > 0) ? 1 : -1);
        this.abpBase = this.setIdArr(response, 'abp');

        if (this.abpBase.length > 0) {
            this.curAbp = this.abpBase[0];
        } else {
            this.curAbp = null;
        }
    }

    /**
     * загрузка ГУ с ограничением по дате
     */
    private async loadLimitedGu() {
        if (!this.curAbp || !this.curRegion || !this.usrId || !this.curVariant) {
            this.guLimitBase = [];
            this.curGuLimit = null;
            return;
        }
        let result: any[] = [];
        this.curGu = null;
        try {
            const params = { abp: this.curAbp.abp, userId: this.usrId, region: this.curRegion.code, date_b: this.curVariant.date_start, date_e: this.variantEndDate }
            result = await fetch(`/api-py/get-dict-gu-for-budget-gkkp/${encodeURI(JSON.stringify(params))}`)
                .then(response => response.json());
        } catch (error) {
            result = [];
            makeToast(this, 'danger', 'Ошибка загрузки ГУ', (error as Error).toString());
        }
        if (result.length > 0) {
            result.sort((a, b) => (a.code > b.code) ? 1 : -1);
            this.curGuLimit = setNameLang(this.$i18n.locale, result[0], 'code');
        } else {
            this.curGuLimit = null;
        }
        this.guLimitBase = result;
    }

    private async loadGu() {
        if (!this.curAbp || !this.curRegion || !this.usrId) {
            this.guBase = [];
            return;
        }
        let result: any[] = [];
        this.curGu = null;
        if (this.curAbp !== null) {
            try {
                // result = await fetch(`/api-py/get-gu-by-abp-user-id/${this.curAbp.abp}/${this.usrId}`)
                result = await fetch(`/api-py/get-gu-by-abp-user-id-region/${this.curAbp.abp}/${this.usrId}/${this.curRegion.code}`)
                    .then(response => response.json());
            } catch (error) {
                result = [];
                makeToast(this, 'danger', 'Ошибка загрузки гос. учреждений', (error as Error).toString());
            }
            if (result.length > 0) {
                this.curGu = setNameLang(this.$i18n.locale, result[0], 'code');
            } else {
                this.curGu = null;
            }
            this.guBase = result;
        }
    }

    private async loadDataTypeDict() {
        let result: any[] = [];
        try {
            result = await fetch('/api-py/dict_budget_data_types/')
                .then(response => response.json());
        } catch (error) {
            result = [];
            makeToast(this, 'danger', 'Ошибка загрузки вида данных', (error as Error).toString());
        }
        result.sort((a, b) => (a.code > b.code) ? 1 : -1);
        this.dataTypeDictBase = result;
        if (this.dataTypeDictBase.length > 0) {
            let el = null;
            el = this.dataTypeDictBase[0];
            this.curDataType = setNameLang(this.$i18n.locale, el);
        }
    }

    private async loadBudgetVersionDict() {
        let result: any[] = [];
        this.curVariant = null;
        this.variantBase = [];
        if (!this.curRegion || !this.curDataType || !this.curPeriod) { return; }
        try {
            const params = { year: this.curPeriod.year, dataType: this.curDataType.code, region: this.curRegion.code };
            result = await fetch(`/api-py/get-variants-for-calcsum/${encodeURI(JSON.stringify(params))}`)
                .then(response => response.json());
            result = result.filter((item: any) => item.is_deleted === false);
        } catch (error) {
            result = [];
            makeToast(this, 'danger', 'Ошибка запроса loadBudgetVersionDict()', (error as Error).toString());
        }
        for (const el of result) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            el.name_ru = variantNameLangSupport(el, 'ru');
            // eslint-disable-next-line @typescript-eslint/camelcase
            el.name_kk = variantNameLangSupport(el, 'kk');
            // eslint-disable-next-line @typescript-eslint/camelcase
            el.name_en = variantNameLangSupport(el, 'en');
        }

        this.variantBase = result;
        if (this.variantBase.length) {
            const tmpObj = this.getFirstActiveApprovVariant();
            if (!this.curVariant || this.curVariant.variant_uuid!== tmpObj.variant_uuid) {
                const flNull = (this.curVariant === null);
                this.curVariant = tmpObj;
                // if (flNull) { this.chgEvent('loadVariants'); }
            }
        } else {
            this.curVariant = null;
        }
    }
    /// --------------------------------------------------------------------------------

    private getFirstActiveApprovVariant() { // получить первый активный вариант, если нет активного, то утверждённая
        let approved: any | null = null;
        for (let i = 0; i < this.variantBase.length; i++) {
            const el = this.variantBase[i];
            if (el.attribute) { return setNameLang(this.$i18n.locale, el); }
            if (!approved && el.status) { approved = setNameLang(this.$i18n.locale, el); }
        }
        if (approved) { return approved; }
        if (this.variantBase.length) { return setNameLang(this.$i18n.locale, this.variantBase[0]); }
        return null;
    }

    private get usrId(): string | null {
        if (!store.state.user.sub) { return null; }
        return store.state.user.sub;
    }

    private setIdArr(arr: any[], codeName: string) {
        const result: any[] = [];
        for (const el of arr) {
            if (result.length > 0 && el[codeName] === result[result.length - 1][codeName]) {
                result[result.length - 1].idArr.push(el.id);
            } else {
                result.push(Object.assign({ idArr: [el.id] }, el));
            }
        }
        return result;
    }

    private mounted() {
        this.getPeriodLst();
        this.getObl();
        this.getModulesAccess();
        this.loadDataTypeDict();

        this.$watch('curAbp', () => {
            this.loadGu();
            this.loadLimitedGu();
            this.chgEvent('watch curAbp');
        });

        this.$watch('curRegion', async () => {
            await this.loadActiveVariant();
            this.getBudgetLevel();
            this.loadBudgetVersionDict();
        });
        this.$watch('curGu', () => {
            if (this.tabIndxSelected === 2) { this.chgEvent('watch curGu'); }
        });
        this.$watch('curGuLimit', () => {
            if (this.tabIndxSelected === 1) { this.chgEvent('watch curGuLimit'); }
        });
        this.$watch('store.state.user.userModules', () => { this.getModulesAccess(); });
        this.$watch('tabIndx', () => { this.getTabIndx(); });
        this.$watch('curDataType', () => { this.loadBudgetVersionDict(); })
        this.$watch('curPeriod', async () => {
            await this.loadActiveVariant();
            this.loadBudgetVersionDict(); 
        });
        this.$watch('curVariant', async () => {
            await this.getVariantEndDate();
            await this.loadLimitedGu();
            this.chgEvent('watch curVariant');
        }, { deep: true });
    }

    private getTabIndx() {
        const arr = [{name: 'limitGu', indx: 0}, {name: 'gkkp', indx: 1}, {name: 'setting', indx: 2}];
        for (let i = 0; i < arr.length; i++) {
            if (i < 0) { break; }
            if (!this.limitGuEnable && arr[i].name === 'limitGu') { 
                arr.splice(i, 1);
                i--;
            }
            if (!this.gkkpEnable && arr[i].name === 'gkkp') { 
                arr.splice(i, 1);
                i--;
            }
            if (!this.settingEnable && arr[i].name === 'setting'){
                arr.splice(i, 1);
            }
        }
        this.tabIndxSelected = arr[this.tabIndx].indx;
    }

    private getModulesAccess() {
        this.settingEnable = false;
        let access = this.getAccess('004.002.010.003');
        this.settingEditable = access.editable;
        this.settingEnable = access.enable;
        // --
        access = this.getAccess('004.002.010.001');
        this.limitGuEditable = access.editable;
        this.limitGuEnable = access.enable;
        // --
        access = this.getAccess('004.002.010.002');
        this.gkkpEditable = access.editable;
        this.gkkpEnable = access.enable;
        this.getTabIndx();
    }

    private getAccess(code: string) {
        const modules: any[] = store.state.user.userModules;
        let editable = false;
        let enable = false;
        for (const row of modules) {
            if (row.modules === code) {
                if (row.access_level !== 1) {
                    editable = true;
                }
                if ([1, 2, 3].includes(row.access_level)) {
                    enable = true;
                }
            }
        }
        return { editable, enable };
    }

    private openFilterByRef(refName: string) {
        const drop: any = this.$refs.drop;
        drop.show(true);
        const refItem: any = this.$refs[refName];
        setTimeout(() => refItem.$el.focus(), 100);
    }

    private chgEvent(source: string) {
        if (this.curVariant && this.curVariant.attribute) {
            this.activeVariant = true;
        } else {
            this.activeVariant = false;
        }
        if (this.tabIndxSelected === 2) {
            const params = { curAbp: this.curAbp, regionProp: this.curRegion, curGu: this.curGu };
            if (this.$refs.refSettings) {
                // eslint-disable-next-line
                // @ts-ignore
                this.$refs.refSettings.chgEvent(params);
            }
        } else if (this.tabIndxSelected === 0 || this.tabIndxSelected === 1) {
            const params = { curYear: null, regionProp: this.curRegion, dataType: this.curDataType, curVariant: this.curVariant, curAbp: this.curAbp, curGu: this.curGuLimit, variantEndDate: this.variantEndDate }
            if (this.curPeriod) { params.curYear = this.curPeriod.year; }
            if (this.tabIndxSelected === 0) {
                if (this.$refs.refLimitGu) {
                    // eslint-disable-next-line
                    // @ts-ignore
                    this.$refs.refLimitGu.chgEvent(params);
                }
            } else if (this.tabIndxSelected === 1) {
                if (this.$refs.refLimitGkkp) {
                    // eslint-disable-next-line
                    // @ts-ignore
                    this.$refs.refLimitGkkp.chgEvent(params);
                }
            }
        }
    }

    private saveClk() {
        if (this.tabIndxSelected === 0) {
            // eslint-disable-next-line
            // @ts-ignore
            this.$refs.refLimitGu.saveClk();
        } else if (this.tabIndxSelected === 1){
            // eslint-disable-next-line
            // @ts-ignore
            this.$refs.refLimitGkkp.saveClk();
        }
    }

    private infoCostForm(info_id: any) {
        const that = this;
        Ax<Blob>(
            {
                url: '/api-py/get-info/' + info_id, //  Тело файла
                method: 'POST',
                data: null,
                responseType: 'blob'
            },
            (data) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                let file = '';
                file = 'Инструкция Лимиты ГУ/ГККП';
                link.setAttribute('download', file + '.pdf');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                makeToast(that, 'danger', 'Ошибка загрузки ГККП', error.toString());
            }
        );
    }

    /**
     * получение variantStartDate для ограничение действия справочников в фильтрах
     */
     private async getVariantEndDate() {
        if (!this.curVariant) {
            this.variantEndDate = null;
            return;
        }
        if (this.curVariant.date_ueb) {
            this.variantEndDate = this.curVariant.date_ueb;
            return;
        }
        if (this.curVariant.attribute === true && this.curVariant.status === false && !this.curVariant.is_deleted) {
            this.variantEndDate = null;
            return;
        }

        let response: any = [];
        try {
            response = await fetch(`/api-py/get-next-variant-date-start/${encodeURI(this.curVariant.date_start)}`);
            if (response.status === 200) {
                response = await response.json();
                this.variantEndDate = response.date;
            } else {
                this.variantEndDate = null;
                makeToast(this, 'danger', 'Ошибка get-next-variant-date-start', `${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            this.variantEndDate = null;
            makeToast(this, 'danger', 'Ошибка get-next-variant-date-start', (error as Error).toString());
        }
    }


        // ----- закгрузка активного варианта
    private async loadActiveVariant() {
        if (!this.curPeriod.year || !this.curRegion) { return; }
        const params = { year: this.curPeriod.year, region: this.curRegion.code };
        const url = `/api-py/get--active-variants-for-calcsum/${encodeURI(JSON.stringify(params))}`;
        let response: any = [];
        try {
            response = await fetch(url);
            if (response.status === 200) {
                response = await response.json();
                if (response.length) {
                    for (let i = 0; i < this.dataTypeDict.length; i++) {
                        if (response[0].data_type.toString() === this.dataTypeDict[i].code) {
                            this.curDataType = this.dataTypeDict[i];
                            break;
                        }
                    }
                }
            } else {
                makeToast(this, 'danger', 'Ошибка загрузки активных вариантов', `${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            response = [];
            makeToast(this, 'danger', 'Ошибка загрузки активных вариантов', (error as Error).toString());
        }
    }
}
