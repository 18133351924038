
































import {Component, Vue, Prop} from 'vue-property-decorator';
import { setNameLang, makeToast } from '@/modules/budget-request/components/js/some-functions';

@Component({
    name: 'c-add-modal'
})
export default class CAddModal extends Vue {
    @Prop({
        required: true,
        default: ''
    })
    private nameDict!: string;

    @Prop({
        required: true,
        default: ''
    })
    private codeDict!: string;

    private showModal = false;
    private dictLst: any[] = [];
    private search = '';
    private checkedAll = false;
    private checkedArr: boolean[] = [];


    private get dict() {
        if (this.search) {
            const result: any[] = [];
            for (const el of this.dictLst) {
                if (this.codeDict && el[this.codeDict] && (el[this.codeDict]).toString().toLowerCase().includes(this.search.toLocaleLowerCase())) {
                    result.push(el);
                } else if (el.name && el.name.toLocaleLowerCase().includes(this.search.toLocaleLowerCase())) {
                    result.push(el);
                }
            }
            return result;
        } else {
            return this.dictLst;
        }
    }

    private openModal(dictLst: any[]) {
        this.search = '';
        this.checkedAll = false;
        this.checkedArr = [];
        this.showModal = true;
        this.dictLst = [];
        for (let i = 0; i < dictLst.length; i++) {
            const el = JSON.parse(JSON.stringify(dictLst[i]));
            this.checkedArr.push(this.checkedAll);
            el._indx = i;
            this.dictLst.push(setNameLang(this.$i18n.locale, el));
        }
    }

    private chkClk() {
        for (let i = 0; i <  this.checkedArr.length; i++) {
            this.checkedArr[i] = this.checkedAll;
        }
    }

    private addClk() {
        const result: any[] = [];
        for (let i=0; i < this.dictLst.length; i++) {
            if (this.checkedArr[i]) {
                result.push(this.dictLst[i]);
            }
        }
        if (result.length === 0){
            makeToast(this, 'warning', 'Добавление', 'Нет выбранных элементов');
            return;
        }
        this.$emit('add', result);
        this.showModal = false;
    }

}
